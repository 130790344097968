//
//
//
//
//
//
//
//
//
//
//
//
//

  import normalHead from '@/views/components/ishop/header.vue'
  export default {
    name: "province",
    props: ['province'],
    data() {
      return {
        popupProvince:false,
        headValue: {
          name: '省份',
          close: true,
        },
      }
    },
    mounted() {
        this.Bus.$on('changeProvince', popupProvince => {  
            this. popupProvince =  popupProvince
        });
    },
    methods:{
     selectProvince(item){
        this. popupProvince = false
        sessionStorage.setItem("province", item.txt)
        sessionStorage.setItem("provinceId", item.regionId)
        sessionStorage.removeItem("city")
        sessionStorage.removeItem("hospital")
        // location.reload()
        this.Bus.$emit('selectProvince',item)
     }
    },
    components: {
      normalHead,
    }
  }
