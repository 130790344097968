//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import normalHead from '@/views/components/ishop/header.vue'
  import { miceService } from '@/service/miceService'
  export default {
    name: "hospital",
    props: ['hospital'],
    data() {
      return {
        popupHospital: false,
        headValue: {
          name: '医院',
          close: true,
        },
        getInfo: true,
        params: {
          cityId: "",
          pageSize: 20,
          pageIndex: 1,
          searchInput:''
        }
      }

    },
    created() {
      this.Bus.$on('selectCity', item => {
        this.params.cityId = item.regionId
      });
      this.params.cityId = sessionStorage.getItem("cityId")
      this.Bus.$on('changeHospital', popupHospital => {
        if (this.params.cityId) {
          this.popupHospital = popupHospital.popupHospital
          this.params.pageIndex = popupHospital.pageIndex
          this.params.pageSize = popupHospital.pageSize
          this.getInfo = true
        } else {
          this.toast({
            message: '请先选择国家/省份/城市',
            duration: 2000,
            className: 'ttt'
          });
        }
      });
    },

    methods: {
      selectHospital(item){
        let popupHospital = {
            pageIndex:0,
            pageSize:20,
            popupHospital:false
        }
        if(item.name=="数据已到底>>>"){
          item.name=""
        }
        this.Bus.$emit('changeHospital', popupHospital);
        this.Bus.$emit('selectHospital', item.name);
        
        sessionStorage.setItem("hospital", item.name)
        sessionStorage.setItem("hospitalId", item.rowId)
        
        // location.reload()
      },
      loadMore(){
        this.params.pageIndex++
        if(this.getInfo){
          this.getHospitalList()
        }
      },
      getHospitalList: async function () {
        let params = this.params
        if(!this.popupHospital){return;}
        const res = await miceService.getHospitalList(params)
        res.rows.forEach((item)=> {
          this.hospital.push(item)
        })
        if (res.rows.length==0) {
          this.toast({
            message: '已到医院列表结尾',
            duration: 2000,
            className: 'ttt'
          });
          // let mseeage = {name: "数据已到底>>>", class: "message"}
          // this.hospital.push(mseeage)
          this.getInfo = false
        }
        // this.Bus.$emit('changeHospitalList',HospitalList)
      },
      searchHospital:function () {
        this.$emit('keywordSearchHosipital',this.params.searchInput)
      }
    },
    components: {
      normalHead,
    }
  }
