//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import normalHead from '@/views/components/ishop/header.vue'
  export default {
    name: "city",
    props: ['city'],
    data() {
      return {
        popupCity: false,
        headValue: {
          name: '城市',
          close: true,
        },
        params: {
          'regionId': "",
          'type': 2
        }
      }

    },
    created() {
      this.Bus.$on('selectProvince', item => {
        this.params.regionId = item.regionId
      });
      this.params.regionId = sessionStorage.getItem("provinceId")
      this.Bus.$on('changeCity', popupCity => {
        if (this.params.regionId) {
          this.popupCity = popupCity
        } else {
          this.toast({
            message: '请先选择国家/省份',
            duration: 2000,
          });
        }
      });

    },
    methods: {
      selectCity(item){
        var popupCity = false
        this.Bus.$emit('changeCity', popupCity);
        sessionStorage.setItem("city", item.txt)
        sessionStorage.setItem("cityId", item.regionId)
        sessionStorage.setItem("pingRegionId", item.pingRegionId)
        sessionStorage.removeItem("hospital")
        // location.reload()
        this.Bus.$emit('selectCity', item)
      }
    },
    components: {
      normalHead,
    }
  }
